<template>
    <div>
        <div class="row mb-4">
            <div class="col-12">
                <h4>
                    Proxy Stats Report.
                </h4>
            </div>
        </div>
        <div class="row listing">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-inline">
                            <div class="form-group mr-1">
                                <input v-on:keyup.enter="search" type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                            </div>
                            <div class="form-group mr-1">
                                <input v-on:keyup.enter="search" type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                            </div>
                            <button type="button" class="btn text-white mr-1" style="background-color: #383838" @click="search"><i class="fa fa-search" title="Search"></i></button>
                            <button type="button" @click="exportToExcel()" class="btn btn-success">Export</button>
                        </div>
                    </div>
                </div>

                <hr/>

                <div class="listing">
                    <table class="table table-hover bg-light text-dark">
                        <thead>
                        <tr>
                            <td>Error Code</td>
                            <td>IP</td>
                            <td>Port</td>
                            <td>Country</td>
                            <td>State</td>
                            <td>City</td>
                            <td>Provider</td>
                            <td>Status</td>
                            <td>Date</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="data in report.data" v-bind:key="data._id">
                            <td v-if="data.data && data.data.errorCode == '407' ">{{(data.data) ? data.data.errorCode +' '+'Invalid Authentication': 'N/A'}}</td>
                            <td v-if="data.data && data.data.errorCode == '503' ">{{(data.data) ? data.data.errorCode +' '+'Service Unavailable': 'N/A'}}</td>
                            <td>{{(data.data) ? data.data.ip : 'N/A'}}</td>
                            <td>{{(data.data) ? data.data.port : 'N/A'}}</td>
                            <td>{{(data.data) ? data.data.country : 'N/A'}}</td>
                            <td>{{(data.data) ? data.data.state : 'N/A'}}</td>
                            <td>{{(data.data) ? data.data.city : 'N/A'}}</td>
                            <td>{{data.provider}}</td>
                            <td v-if="data.data.status!='pending' && data.data.status!='active'"><span class="badge badge-danger">{{(data.data) ? data.data.status : 'N/A'}}</span></td>
                            <td v-if="data.data.status!='bad' && data.data.status!='active' && data.data.status!='disabled'"><span class="badge badge-warning">{{(data.data) ? data.data.status : 'N/A'}}</span></td>
                            <td v-if="data.data.status!='bad' && data.data.status!='pending' && data.data.status!='disabled'"><span class="badge badge-success">{{(data.data) ? data.data.status : 'N/A'}}</span></td>
                            <td>{{data.date}}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="row">
                        <div class="col-12 col-sm-9">
                            <v-pagination
                                v-model="page"
                                :pageCount="Math.ceil(report.total/max)"
                                :classes="bootstrapPaginationClasses"
                                :labels="customLabels"
                                @input="search"></v-pagination>
                        </div>
                        <div class="col-12 col-sm-3 text-right">
                            <span>{{report.data.length * Number(page|1)}}</span> of <span>{{report.total}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import swal from "sweetalert";
    import vPagination from "vue-plain-pagination";

    export default {
        name: "Proxy.Report",
        props: ['user'],
        components: {
            vPagination
        },
        data: function () {
            return {
                clients:[],
                item: {
                    geo:{},
                    timezone: ''
                },
                customerQuery:'',
                action:{mobile:'',status:'',method:''},
                selectedCamp:[],
                items: {total:0, data:[]},
                editor: false,
                countries:[],
                states:[],
                cities:[],
                selected:{},
                report: [],
                geos:{},
                filter: {name:'', client:'', proxy:'', status:'',country:'',state:'',method:''},
                max: 25,
                page: 1,
                bootstrapPaginationClasses: {
                    ul: 'pagination',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                customLabels: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                }
            }
        },
        created() {
            this.search();
        },
        methods:{
            search: function(obj){
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0

                var request = {'apikey': this.$root.apikey, id:'',from:'',to:''};

                if(this.filter.from) request.from = this.filter.from.replace(/-/g,'/');
                if(this.filter.to) request.to = this.filter.to.replace(/-/g,'/');
                request.page = page
                request.max = this.max;

                if(this.$route.params.id){
                    request.id = this.$route.params.id;
                } else {
                    return;
                }
                this.$root.preloader = true
                axios.get(`${this.$root.serverUrl}/admin/proxies/stats`, {params:request}).then((resp)=>{
                    //Store the stats
                    this.report = Object(obj, {});
                    this.report = resp.data;
                    this.$root.preloader = false
                }).catch((err)=>{
                    this.$root.preloader = false
                    console.log('report.error', err)
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                })
            },
            exportToExcel: function(){
                var request = {'apikey': this.$root.apikey, id:''}
                if(this.$route.params.id){
                    request.id = this.$route.params.id;
                } else {
                    return;
                }
                this.$root.preloader = true
                axios({
                    url: `${this.$root.serverUrl}/admin/proxies/export`,
                    params: request,
                    method: 'GET',
                    responseType: 'blob',
                }).then((resp) => {
                    var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `report_${new Date().toTimeString()}.csv`);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    this.$root.preloader = false
                }).catch((err)=>{
                    this.$root.preloader = false
                    console.log('report.error', err)
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                });
            }
        }
    }
</script>

<style scoped>
    button{
        margin-left: 2px;
    }
</style>
